import axios from 'axios';

const axiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_BACKEND_URL ||
    'https://1yogn9tarh.execute-api.us-east-1.amazonaws.com',
});

const errorHandler = (err) => {
  console.log('Error aHandler err', err);
};

axiosInstance.interceptors.response.use((response) => {
  if (response.data.status === 1) {
    // console.log('response Axios', response);
    return response.data?.data || 'Success';
  }
  console.log('response.config', response.config);
  if (response.config.query) {
    const err = new Error(response.data?.msg);
    err.config = response.config;
    return errorHandler(err);
  }
  return null;
}, errorHandler);

export default axiosInstance;
