import {
  Linkedin,
  Instagram,
  Facebook,
  Youtube,
  Twitter,
} from 'react-bootstrap-icons';
import SectImg from '../../../assets/section3.png';
import AppStore from '../../../assets/app-store.png';
import PlaySotre from '../../../assets/play-store.png';
import { useNavigate } from 'react-router-dom';

const SOCIAL_ICONS = [
  {
    IconComp: Linkedin,
    link: '',
    color: 'steelblue',
    gotoUrl: 'https://www.linkedin.com/company/aluma-salon/',
  },
  {
    IconComp: Instagram,
    link: '',
    color: 'orangered',
    gotoUrl: 'https://www.instagram.com/aluma.salon/',
  },
  { IconComp: Facebook, link: '', color: 'steelblue' },
  { IconComp: Youtube, link: '', color: 'red' },
  { IconComp: Twitter, link: '', color: 'steelblue' },
];
export default function Section3() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        backgroundImage: `url(${SectImg})`,
        height: '100vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div
        style={{
          paddingLeft: '35vw',
          paddingRight: '5vw',
          paddingTop: '15vh',
          height: '100%',
          paddingBottom: '5vh',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 30,
              fontSize: 38,
            }}
          >
            <div
              style={{ fontSize: 38, fontWeight: 900, letterSpacing: '-4%' }}
            >
              Contact us
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ fontWeight: 200 }}>
                #247/4 Habitech Nest
                <br /> 3rd Floor, ECity
                <br />
                Bangalore- 560100
              </div>

              <div
                style={{
                  fontSize: 20,
                  fontWeight: 100,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                <div
                  onClick={() => {
                    navigate('/privacy');
                  }}
                >
                  Return & Refund Policy
                </div>
                <div
                  onClick={() => {
                    navigate('/termsandcondition');
                  }}
                >
                  Terms of service
                </div>
                <div
                  onClick={() => {
                    navigate('/privacy');
                  }}
                >
                  Privacy policy
                </div>
              </div>
            </div>
            <div style={{ fontSize: 35, fontWeight: 500 }}>
              <div>Email: support@aluma.salon</div>
              <div>Phone: +91 7892962859</div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: 40,
              justifySelf: 'end',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                fontSize: 38,
                fontWeight: 700,
              }}
            >
              <div style={{}}>Social</div>
              <div style={{ display: 'flex', gap: 10 }}>
                {SOCIAL_ICONS.map((item, idx) => {
                  const { IconComp, link, color, gotoUrl } = item;
                  return (
                    <div
                      key={idx}
                      onClick={() => {
                        if (gotoUrl) window.open(gotoUrl);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <IconComp color={color || 'steelblue'} />
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <div style={{ fontSize: 38, fontWeight: 900 }}>Available On</div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  cursor: 'pointer',
                }}
              >
                <img
                  src={PlaySotre}
                  onClick={() => {
                    window.open(
                      'https://play.google.com/store/apps/details?id=com.aluma.salon'
                    );
                  }}
                />
                <img
                  src={AppStore}
                  onClick={() => {
                    window.open(
                      'https://apps.apple.com/in/app/aluma-salon/id6498151574'
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
