import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { ArrowRight, ArrowRightCircleFill } from 'react-bootstrap-icons';
import { useForm, Controller } from 'react-hook-form';
import SectImg from '../../../assets/section2.png';
import { useEffect, useState } from 'react';
import { submitOnboarding } from '../../../api';
import { Spinner } from 'react-bootstrap';

const FormInput = ({
  label,
  placeholder,
  type,
  name,
  control,
  errors,
  register,
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
      <Form.Group className='mb-3' controlId={name}>
        <Form.Label style={{ fontSize: 14 }}>
          {label || 'Email address'}
        </Form.Label>
        {/* <Controller
          control={control}
          name={name}
          // defaultValue=""
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Form.Control
              onChange={onChange}
              value={value}
              ref={ref}
              isInvalid={errors?.[name]}
              required
              type={type || 'text'}
              placeholder={placeholder || ''}
            />
          )}
        /> */}
        <Form.Control
          type={type || 'text'}
          placeholder={placeholder || ''}
          {...register(name, { required: 'Correo es obligatorio' })}
          style={{ width: '100%' }}
        />
        {errors[name] && (
          <Form.Text className='text-danger'>
            Please type a valid {label}.{/* {errors[name].message} */}
          </Form.Text>
        )}
        {/* <Form.Control.Feedback type='invalid' style={{ fontWeight: '700' }}>
          Please type a valid {label}.
        </Form.Control.Feedback> */}
      </Form.Group>
    </div>
  );
};
export default function Section2() {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const initialValues = {
    email: '',
    name: '',
    message: '',
  };
  const {
    setError,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
    register,
  } = useForm({
    mode: 'onTouched',
    // reValidateMode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
  });
  const [validated, setValidated] = useState(false);

  const onSubmit = async (data) => {
    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // }
    setLoading(true);
    console.log('data', data);
    try {
      const data2 = await submitOnboarding(data);
      console.log('data2', data2);
      reset(initialValues);
      setSubmitted(true);
    } catch (err) {
      console.log('err', err);
    }
    setLoading(false);

    setValidated(true);
  };
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setValidated(true);
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  return (
    <div
      style={{
        backgroundImage: `url(${SectImg})`,
        height: '100vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: 'inherit',
          color: '#FFF',
          paddingLeft: '8vh',
          gap: 30,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
          <div style={{ height: 2, width: 40, backgroundColor: '#FFF' }}></div>
          <div style={{ fontSize: 16, fontWeight: 700 }}>
            Onboard Your Salon & Increase Your sale by 50%
          </div>
        </div>
        <div>
          <Form
            // noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: 'flex', flexDirection: 'column', gap: 40 }}
          >
            <div style={{ width: '50%' }}>
              <div
                style={{
                  display: 'flex',
                  gap: 10,
                  width: '100%',
                  // justifyContent: 'space-between',
                }}
              >
                <div style={{ flex: 1 }}>
                  {' '}
                  <FormInput
                    {...{
                      control,
                      register,
                      errors,
                      name: 'name',
                      label: 'Name',
                      placeholder: 'abc',
                    }}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <FormInput
                    {...{
                      control,
                      register,
                      errors,
                      name: 'email',
                      label: 'Email',
                      placeholder: 'abc@abc.abc',
                      type: 'email',
                    }}
                  />
                </div>
              </div>
              <div>
                <FormInput
                  {...{
                    control,
                    register,
                    errors,
                    name: 'message',
                    label: 'Message',
                    placeholder: 'Your Message',
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              {submitted ? (
                <div style={{ fontSize: 18 }}>
                  Your request is submitted successfully
                </div>
              ) : (
                <>
                  {loading ? (
                    <Spinner animation='border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                  ) : (
                    <Button
                      type='submit'
                      style={{
                        backgroundColor: '#000',
                        padding: 0,
                        borderRadius: '50%',
                      }}
                    >
                      <ArrowRightCircleFill size={80} style={{ zIndex: 999 }} />
                    </Button>
                  )}
                </>
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
