import SectImg from '../../../assets/section1.png';
export default function Section1() {
  return (
    <div
      style={{
        backgroundImage: `url(${SectImg})`,
        height: '100vh',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#FFF',
          height: 'inherit',
          fontSize: '250px',
          paddingRight: 10,
          letterSpacing: '-4%',
          fontFamily: 'Anton',
          paddingTop: '10vh',
        }}
      >
        <div>ALUMA .</div>
        <div
          style={{
            alignSelf: 'flex-end',
            top: -100,
            right: '20vw',
            position: 'relative',
            fontSize: '180px',
            // textAlign: 'end',
          }}
        >
          salon
        </div>
      </div>
    </div>
  );
}
