import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';

function HomePage() {
  return (
    <>
      {/* <div>Hello</div> */}
      <Section1 />
      <Section2 />
      <Section3 />
    </>
  );
}

export default HomePage;
